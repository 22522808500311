@import './variables';

.button {
   min-width: 101px;
   color: #fff;
   text-align: center;
   text-transform: uppercase;
   font-size: 14px;
   transition: 0.3s transform, 0.3s box-shadow;
   border: none;
   background-color: transparent;
   cursor: pointer;

   &__long {
      display: block;
      width: 170px;
      margin: 45px auto 0 auto;
   }

   .inner {
      position: relative;
      background-color: $main-color;
      line-height: 18px;
      padding: 10px 18px;
      clip-path: polygon(
         0 10px,
         10px 0,
         calc(100% - 10px) 0,
         100% 10px,
         100% calc(100% - 10px),
         calc(100% - 10px) 100%,
         10px 100%,
         0 calc(100% - 10px)
      );
      transition: background-color 0.3s ease, box-shadow 0.3s ease;
   }

   &__main,
   &__secondary {
      &:hover {
         color: #fff;
      }
   }

   &__secondary .inner {
      background-color: $grey;
      background: linear-gradient(135deg, $grey 0%, darken($grey, 10%) 100%);
   }

   &__main .inner {
      background-color: $main-color;
      background: linear-gradient(135deg, $main-color 0%, darken($main-color, 10%) 100%);
   }

   &:hover {
      transform: translateY(-4px);
   }

   &:disabled {
      filter: grayscale(0.5);
      cursor: not-allowed;
      .inner {
         background: linear-gradient(135deg, $grey 0%, lighten($grey, 10%) 100%);
      }
   }
}
