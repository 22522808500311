@import './variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');
@import './button';

* {
   font-family: 'Roboto Condensed', sans-serif;
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

ul,
li,
dl {
   list-style-type: none;
   margin: 0;
   padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   margin: 0;
}

a {
   text-decoration: none;
   color: inherit;
   display: inline-block;
   &:hover {
      text-decoration: none;
      color: inherit;
   }
}

p {
   margin: 0;
   padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

.app {
   width: 100%;
   max-width: 1100px;
   margin: 0 auto;
   padding: 50px 20px;
   position: relative;

   .bg-decoration {
      position: absolute;
      right: -174px;
      bottom: -70px;
      z-index: -1;
   }
}

main {
   margin-top: 50px;
   position: relative;
}

.pulse {
   animation: pulse 1.5s ease-in-out 0.5s infinite;
}

@keyframes pulse {
   0% {
      opacity: 1;
   }
   50% {
      opacity: 0.4;
   }
   100% {
      opacity: 1;
   }
}

@media (max-width: 1200px) {
   .app {
      padding: 50px 40px;
      .bg-decoration {
         display: none;
      }
   }
}

@media (max-width: 992px) {
   .app {
      padding: 50px 30px;
   }
}

@media (max-width: 768px) {
   .app {
      padding: 40px 20px;
   }
}

@media (max-width: 576px) {
   .app {
      width: 90%;
      padding: 30px 15px;
   }
}
